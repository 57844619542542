import React, { useCallback, useEffect, useMemo } from 'react';
import { compose } from 'redux';
import Box from '@rexlabs/box';
import { ReactForms, Form, Field } from '@rexlabs/form';
import { RenderLoading } from 'view/components/render-loading';
import { Dropdown } from '@rexlabs/select';
import { Checkbox } from '@rexlabs/checkbox';
import _, { get } from 'lodash';
import { withQuery, query } from '@rexlabs/model-generator';
import settings from 'data/models/entities/settings';
import { formsQuery } from 'data/queries/forms';
import { PrimaryButton } from '@rexlabs/button';
import { withToast } from 'view/components/toast';
import { TextInput } from '@rexlabs/text-input';

function getWebsiteId(props) {
  return get(props, 'match.params.websiteId');
}

const formsSettingsQuery = query`{
  ${settings} (websiteId: ${getWebsiteId}, category: forms)  {
    key
    value
    label
  }
 }
`;

const formsSettings = {
  name: 'formsSettings',
  mapPropsToValues: (props) => {
    // TODO BUG: Sometimes this runs twice, causing forms to not show in dropdowns.

    const { settings, forms } = props;
    const settingsData = get(settings, 'list.items');
    const formsData = get(forms, 'list.items');

    const data = Object.assign(
      {},
      ...settingsData.map((object) => {
        const value = object?.value ? object?.value[0] : null;
        const form = _.find(formsData, (o) => o.id === value);

        const checkboxesAndInputs = [
          'sales_market_appraisal_send_to_listing_agent',
          'rental_appraisal_send_to_property_manager',
          'property_enquiry_send_to_listing_agent',
          'maintenance_request_send_to_property_manager',
          'holiday_enquiry_send_to_property_manager',
          'recaptcha_site_key',
          'recaptcha_secret_key'
        ];

        // Checkboxes and text fields have a different structure to dropdowns
        if (checkboxesAndInputs.indexOf(object.key) > -1) {
          return { [object.key]: value };
        }

        return {
          [object.key]: value ? { value, label: form?.definition?.name } : null
        };
      })
    );

    return data;
  }
};

const SectionTitle = (props) => {
  return (
    <Box
      flex={1}
      flexDirection={'row'}
      padding='10px'
      alignItems={'center'}
      marginTop='20px'
    >
      <h2
        style={{
          fontSize: '18px',
          fontWeight: '700',
          marginRight: '20px'
        }}
      >
        {props.title}
      </h2>
    </Box>
  );
};

function FormsSettings(props) {
  const forms = useMemo(() => {
    return props.forms.list.items
      .map((item) => ({
        label: item?.definition?.name,
        value: item.id
      }))
      .concat({ value: null, label: 'No form' })
      .sort((a, b) => (a.label < b.label ? -1 : 1));
  }, [props.forms.list.status]);

  const handleSubmit = useCallback(async (values) => {
    const { settings, addToast } = props;

    const data = _.map(values, (value, key) => {
      return {
        key,
        value: [value?.value !== undefined ? value.value : value],
        category: settings?.list?.args?.category || 'form'
      };
    });

    return settings
      .updateSettings({
        websiteId: get(props, 'match.params.websiteId'),
        data: {
          settings: data
        }
      })
      .then(() => {
        window.analytics?.track?.('siteloft.design', {
          action: 'updated header behaviour',
          location: 'Header behaviour'
        });

        addToast?.({
          type: 'success',
          title: 'Saved',
          content: 'Settings successfully updated'
        });
      })
      .catch((e) =>
        addToast?.({
          title: 'Error',
          type: 'error',
          content: e?.message
        })
      );
  });

  useEffect(() => {
    window.analytics?.page?.('Forms Settings');
  }, []);

  return (
    <Box flex={1} style={{ overflow: 'auto' }}>
      <Box flex={1} padding={'20px'}>
        <RenderLoading
          isLoading={
            props.settings.list.status === 'loading' ||
            props.forms.list.status === 'loading'
          }
        >
          <ReactForms
            initialValues={formsSettings.mapPropsToValues(props)}
            handleSubmit={handleSubmit}
          >
            {({ submitForm, isSubmitting }) => (
              <Form name='formsSettings' style={{ width: '80%' }}>
                <Box
                  flex={1}
                  flexDirection={'row'}
                  padding='10px'
                  alignItems={'center'}
                >
                  <h1
                    style={{
                      fontSize: '28px',
                      fontWeight: '700',
                      marginRight: '20px'
                    }}
                  >
                    Form Settings
                  </h1>

                  <PrimaryButton
                    onClick={submitForm}
                    isSubmitting={isSubmitting}
                  >
                    Save
                  </PrimaryButton>
                </Box>
                <Box flexDirection={'column'} flexWrap={'wrap'}>
                  <SectionTitle title={'Enquiry Forms'} />
                  <Box padding='10px' flex={1}>
                    <Field
                      label='Enquiry'
                      name='enquiry'
                      Input={Dropdown}
                      inputProps={{
                        items: forms
                      }}
                      optional={false}
                    />
                  </Box>

                  <Box padding='10px' paddingBottom='5px' flex={1}>
                    <Field
                      label='Holiday Enquiry'
                      name='holiday_enquiry'
                      Input={Dropdown}
                      inputProps={{
                        items: forms
                      }}
                      optional={false}
                    />
                  </Box>

                  <Box
                    padding='10px'
                    paddingBottom='5px'
                    flex={1}
                    flexDirection={'column'}
                  >
                    <Field
                      label='Property Enquiry'
                      name='property_enquiry'
                      Input={Dropdown}
                      inputProps={{
                        items: forms
                      }}
                      optional={false}
                    />
                    <Field
                      name='property_enquiry_send_to_listing_agent'
                      optional={false}
                      Input={Checkbox}
                      inputProps={{
                        label: 'Send to listing agent'
                      }}
                    />
                  </Box>

                  <SectionTitle title={'Agency Contact/Appraisal Forms'} />

                  <Box padding='10px' flex={1}>
                    <Field
                      label='Sales/Market Appraisal (Agency)'
                      name='sales_market_appraisal'
                      Input={Dropdown}
                      inputProps={{
                        items: forms
                      }}
                      optional={false}
                    />
                  </Box>

                  <Box padding='10px' flex={1}>
                    <Field
                      label='Rental Appraisal (Agency)'
                      name='rental_appraisal'
                      Input={Dropdown}
                      inputProps={{
                        items: forms
                      }}
                      optional={false}
                    />
                  </Box>

                  <Box padding='10px' flex={1}>
                    <Field
                      label='Contact (Agency)'
                      name='agency_contact'
                      Input={Dropdown}
                      inputProps={{
                        items: forms
                      }}
                      optional={false}
                    />
                  </Box>

                  <Box padding='10px' flex={1} flexDirection={'column'}>
                    <Field
                      Input={Checkbox}
                      name={'agency_contact_send_to_agency'}
                      inputProps={{
                        label: 'Send agency enquiries to agency'
                      }}
                      optional={false}
                      HelpTooltipContent={() =>
                        'Forms displayed on agency profiles are configured in the agency profile settings'
                      }
                    />
                  </Box>

                  <Box
                    padding='10px'
                    paddingBottom='5px'
                    flex={1}
                    flexDirection={'column'}
                  >
                    <Field
                      name='agency_contact_send_to_admin'
                      optional={false}
                      Input={Checkbox}
                      inputProps={{
                        label: 'Send agency enquiries to website admin'
                      }}
                    />
                  </Box>

                  <SectionTitle title={'Agent Contact/Appraisal Forms'} />

                  <Box padding='10px' flex={1}>
                    <Field
                      label='Sales Appraisal (Agent)'
                      name='agent_sales_appraisal'
                      Input={Dropdown}
                      inputProps={{
                        items: forms
                      }}
                      optional={false}
                    />
                  </Box>

                  <Box padding='10px' flex={1}>
                    <Field
                      label='Rental Appraisal (Agent)'
                      name='agent_rental_appraisal'
                      Input={Dropdown}
                      inputProps={{
                        items: forms
                      }}
                      optional={false}
                    />
                  </Box>

                  <Box padding='10px' flex={1}>
                    <Field
                      label='Contact (Agent)'
                      name='agent_contact'
                      Input={Dropdown}
                      inputProps={{
                        items: forms
                      }}
                      optional={false}
                    />
                  </Box>

                  <Box paddingLeft='10px' flex={1} flexDirection={'column'}>
                    <Field
                      name='agent_contact_send_to_agent'
                      optional={false}
                      Input={Checkbox}
                      HelpTooltipContent={() =>
                        'Forms displayed on agent profiles are configured in the agents profile settings'
                      }
                      inputProps={{
                        label:
                          'Send staff profile appraisal/contact requests to staff member'
                      }}
                    />
                  </Box>

                  <SectionTitle title={'Application Forms'} />

                  <Box padding='10px' flex={1}>
                    <Field
                      label='Tenancy Application'
                      name='tenancy_application'
                      Input={Dropdown}
                      inputProps={{
                        items: forms
                      }}
                      optional={false}
                    />
                  </Box>

                  <Box padding='10px' flex={1}>
                    <Field
                      label='Pet Application'
                      name='pet_application'
                      Input={Dropdown}
                      inputProps={{
                        items: forms
                      }}
                      optional={false}
                    />
                  </Box>

                  <SectionTitle title={'Request Forms'} />

                  <Box padding='10px' paddingBottom='5px' flex={1}>
                    <Field
                      label='Maintenance Request'
                      name='maintenance_request'
                      Input={Dropdown}
                      inputProps={{
                        items: forms
                      }}
                      optional={false}
                    />
                  </Box>

                  <SectionTitle title={'Notices and Newsletters'} />

                  <Box padding='10px' flex={1}>
                    <Field
                      label='Newsletter'
                      name='newsletter'
                      Input={Dropdown}
                      inputProps={{
                        items: forms
                      }}
                      optional={false}
                    />
                  </Box>

                  <Box padding='10px' flex={1}>
                    <Field
                      label='Tenant Newsletter'
                      name='tenant_newsletter'
                      Input={Dropdown}
                      inputProps={{
                        items: forms
                      }}
                      optional={false}
                    />
                  </Box>

                  <Box padding='10px' flex={1}>
                    <Field
                      label='Vacate Notice'
                      name='vacate_notice'
                      Input={Dropdown}
                      inputProps={{
                        items: forms
                      }}
                      optional={false}
                    />
                  </Box>

                  <Box padding='10px' flex={1}>
                    <Field
                      label='Property Alert'
                      name='property_alert'
                      Input={Dropdown}
                      inputProps={{
                        items: forms
                      }}
                      optional={false}
                    />
                  </Box>

                  <Box padding='10px' flex={1}>
                    <Field
                      label='Development Register Interest'
                      name='development_register_interest'
                      Input={Dropdown}
                      inputProps={{
                        items: forms
                      }}
                      optional={false}
                    />
                  </Box>

                  <SectionTitle title={'reCaptcha'} />

                  <Box padding='10px' flex={1}>
                    <Field
                      name='recaptcha_site_key'
                      label='Site Key'
                      Input={TextInput}
                      optional={false}
                    />
                  </Box>

                  <Box padding='10px' flex={1}>
                    <Field
                      name='recaptcha_secret_key'
                      label='Secret Key'
                      Input={TextInput}
                      optional={false}
                    />
                  </Box>
                </Box>
              </Form>
            )}
          </ReactForms>
        </RenderLoading>
      </Box>
    </Box>
  );
}

export default compose(
  withToast,
  withQuery(formsSettingsQuery),
  withQuery(formsQuery)
)(FormsSettings);
